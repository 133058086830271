@font-face {
  font-family: "universe"; /* Project id 4215837 */
  /* Color fonts */
  src: 
       url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAK0AAwAAAAABVAAAAJmAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIjQjHgZgADwKg0yCdgE2AiQDGAsWAAQgBYF8ByAbEQTIBp4nu3/PndkPOCA+pSRvA6iDO1cujcodWO/UqZqEQrkHzCQJUAzXptXWxsZFcwL1Clgs0B5UrPUyjF15ESEAGnAHxmCHAgSNoDDQKOwAezQOCI4onAABBiL1yacsnWeNGKi1B7GKMAxkL6w31HFVD6T5y0g982U+iCOevJOekR7HHcZ8W65tlg20DfcWAqBQj4AxJOQVW76rLAdfDKYwy1i10DthWeBDS9PWx6B7qmJYztDxDSuGgesgzMARE1YC0hUKyd7Hx3espx7pHBkc6e0oqK/NK2jY7m+6uIgpO8R08duBHBTfHdicnTF9TWfT39+UwujIjlcQYHsJnLe/BmK+ebnpyBH//IIjpv+kaYd9c/MPb/cNnHLQLy/XxlceCijI+2nLkzdpj17lurx5/Tr98euXqSMFrvOyS5JaWaEjl2gbkbpwIID734tyJwSYvPvB/bv8axeU8rBDBCQfxFFK8Dce9oBAHoIIY3Rk2Ym44eG4E2VleNHw8Im4svSqaFuca+z58+fOxXqY6QUPjl+Ior9ibhAeFZ+/eCP2wcXz8A/HH/Bid/E255OXVow4xZh2cVPW5zXdfbbFbcP4ZpZI5Lp1JynZO1Inp56r8Fjx31Efcu6jcfyefzjSumOgCwF7VA/5DaQMiHQ7blBF8oVVfyBStvJB/vq2bjXjzMJRuhv37ElHI4YTgifEMYU7lyGjWUQ4YzCGXMaOYHrftWcMu+mjnSb6qaObHurIDq810EcrVXQzl1nMMQvLSSfDNuJQ1/bTZvKudWxjnPzSQhMdtCttMww0qqsx5lpX+IkSBAA=') format('woff2'),
       url('iconfont.woff?t=1692330569579') format('woff'),
       url('iconfont.ttf?t=1692330569579') format('truetype');
}

.universe-icon {
  font-family: "universe" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.univ-icon-reverse:before {
  content: "\e63b";
}

.univ-icon-collapse-caret:before {
  content: "\e639";
}

.univ-icon-close:before {
  content: "\e63a";
}

